<template>
    <div class="upload_image">
        <div :id="`file-upload-dropzone-form-${fileUuid}`" class="dropzone">
            <div class="dz-message" data-dz-message>
                <div>
                    <img src="@/assets/images/image2.png" class="icon">
                    <h4>Click to Upload Files</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { uuid } from 'vue-uuid'

    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'

    export default {
        data () {
            return {
                fileUuid: 'file-'+uuid.v4(),
            }
        },

        props: {
            modelValue: {
                type: String,
                default: '',
            }
        },

        emit: ['update:modelValue'],

        mounted () {
            const vm = this;

            vm.initDropzone();
        },

        methods: {
            initDropzone () {
                const vm = this;

                let dropzone = new Dropzone(`#file-upload-dropzone-form-${vm.fileUuid}`, {
                    url: 'dummy/route',
                    autoProcessQueue: false,
                    uploadMultiple: false,
                    autoQueue: false,
                    createImageThumbnails: true,
                    maxFiles: 1,
                    addedfiles: function (files) {
                        const file  = files[0];
                        file.previewElement.classList.add("dz-success");
                        file.previewElement.classList.add("dz-complete");

                        if (file.status == 'error') {
                            file.previewElement.addEventListener("click", function() {
                                dropzone.removeFile(file);
                            });
                        } else {
                            vm.$emit('update:modelValue', 'true');
                        }
                    },
                });
            },
        }
    }
</script>

<style  scoped>
    .action_content .upload_image .dropzone,
    .action_content .upload_image label {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .action_content .upload_image .dropzone .icon,
    .action_content .upload_image label .icon {
        height: 70px;
        width: 70px;
    }

    .upload_image .dropzone {
        border: 1.2px dashed #95BFFF;
        background: #FAFCFD;
        display: block;
        padding: 25px 25px 15px 25px;
    }
</style>
